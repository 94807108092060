// Override default variables before the import

@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Ubuntu:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

$body-bg: #eee;
$top: #bbb;

body {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

p,
td,
th,
div,
ul,
li {
  //   font-family: "Ubuntu Condensed", sans-serif;
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

a {
  color: #a50404 !important;
}

h1 {
  text-transform: uppercase;
}

.offlineContainer {
  background-color: red;
  color: white;
}

.tablePaginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.pagination {
  margin-bottom: 0;
}
.pagination .page-link,
.pagination .page-link:focus {
  font-size: 36px;
  border: 0;
  background-color: inherit !important;
  line-height: 24px;
  box-shadow: none;
}

.pa .day,
.month {
  font-size: 1em;
  font-weight: 300;
  line-height: 0.7em;
  text-transform: uppercase;
}
.date {
  font-size: 2em;
  line-height: 1.5em;
  font-weight: normal;
}
.time {
  font-size: 0.8em;
  background-color: #bbb;
  padding: 3px;
  margin-bottom: 5px;
}

.card-title {
  color: #a50404;
}

.artist {
  font-size: 1.2em;
  color: #000;
  text-align: left;
  font-weight: 400;
}
.location {
  font-size: 1.3em;
  color: #a50404;
  font-weight: bold;
  text-align: left;
}
// .location {
//   color: #666;
//   text-align: left;
// }
.locationAddress {
  font-size: 1.2em;
  color: #000;
  text-align: left;
  font-weight: 400;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.title {
  font-size: 1.3em;
  color: #a50404;
  font-weight: bold;
  text-align: left;
  font-weight: normal;
}
.eventDate {
  font-size: 1em;
  color: #666666;
  text-align: left;
  font-weight: normal;
}

.eventDetailTab {
  color: #666666 !important;
  font-size: 1.4em !important;
}

.communicationButton {
  text-align: left;
  margin-right: 10px;
  color: #a50404;
}

.scheduleTime {
  text-align: right;
}

.infoBlock {
  border-top: 1px solid #999;
  padding: 0.5em 0 0.5em;
}

.infoLabel {
  font-weight: normal;
  font-size: 1rem;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
